import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsSidenavComponent } from './notifications-sidenav.component';
import { SidenavContentModule } from '../../sidenav/sidenav-content/sidenav-content.module';
import { JumbotronModule } from '../../../shared/jumbotron/jumbotron.module';

@NgModule({
    imports: [
        CommonModule,
        SidenavContentModule,
        JumbotronModule,
    ],
    declarations: [
        NotificationsSidenavComponent,
    ],
    providers: [],
})
export class NotificationsSidenavModule
{
}
