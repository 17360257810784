import { Component, Input } from '@angular/core';
import { PropertyManagementCompany } from '../../../../models/entities/property-management-company';

@Component({
    selector: 'app-sidenav-navigation',
    templateUrl: './sidenav-navigation.component.html',
    styleUrls: ['./sidenav-navigation.component.scss'],
})
export class SidenavNavigationComponent
{
    @Input() pmc: PropertyManagementCompany;

    getRouterLink(endpoint: string) {
        return ['/pmc', this.pmc.id, endpoint];
    }
}
