import { UnifiedBankAccount } from 'app/models/entities/unified-bank-account';

export class TransactionRuleAccount
{
    id: number;
    unifiedAccountId: number;

    unifiedAccount: UnifiedBankAccount;

    constructor(req: any) {
        this.id = req.id;
        this.unifiedAccountId = req.unifiedAccountId;
        if (req.unifiedAccount) {
            this.unifiedAccount = new UnifiedBankAccount(req.unifiedAccount);
        }
    }
}
