import { DataSource } from '@angular/cdk/collections';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { Charge } from '../../models/entities/charge';
import { ChargesService } from './charges.service';
import { PaginationLinks } from '../../models/entities/pagination';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

export interface ChargeDatasourceOptions
{
    category?: number;
    title?: string;
    depositAccount?: number;
    year?: number;
    startDate?: number;
    endDate?: number;
    min?: number;
    max?: number;
    status?: string;
    csv?: number;
    unitTags?: string[];
}

export const ChargesPageSizeOptions = [
    25,
    50,
    100,
    200,
    400,
    600,
    800,
    1000,
];

export class ChargesDatasource implements DataSource<Charge>
{
    private transactionSubject = new BehaviorSubject<Charge[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private countSubject = new BehaviorSubject<number>(0);

    private metaSubject = new Subject<ChargesPaginationMeta>();

    public loading$ = this.loadingSubject.asObservable();
    public count$ = this.countSubject.asObservable();
    public meta$ = this.metaSubject.asObservable();

    private cancelCurrentRequest = new Subject();

    constructor(private chargesService: ChargesService) {
    }

    loadCharges(
            orgId: number,
            search: string = '',
            status: string = 'all',
            sortColumn: string = 'activeAfter',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: ChargeDatasourceOptions = {},
    ) {

        options.csv = 0;
        this.loadingSubject.next(true);

        this.cancelCurrentRequest.next(true);

        this.chargesService.getIssuedCharges(orgId, search, status, sortColumn, sortDirection, pageIndex, perPage, options)
                .pipe(takeUntil(this.cancelCurrentRequest))
                .pipe(
                        catchError((err) => of({data: [], meta: {total: 0}}).pipe(tap(_ => console.error(err)))),
                )
                .subscribe((charges: ChargesResponse) => {
                    this.transactionSubject.next(charges.data);
                    const total = (charges.meta.total) ? charges.meta.total : 0;
                    this.metaSubject.next(charges.meta);
                    this.countSubject.next(total);
                    this.loadingSubject.next(false);
                });
    }

    getCsv(
            orgId: number,
            search: string = '',
            status: string = 'all',
            sortColumn: string = 'activeAfter',
            sortDirection: string = 'desc',
            options: ChargeDatasourceOptions = {},
    ) {
        options.csv = 1;
        return this.chargesService.getIssuedCharges(orgId, search, status, sortColumn, sortDirection, 1, -1, options);
    }

    connect(): Observable<Charge[]> {
        return this.transactionSubject.asObservable();
    }

    disconnect(): void {
        this.transactionSubject.complete();
        this.loadingSubject.complete();
        this.countSubject.complete();
    }

}


export class UnitChargesDatasource implements DataSource<Charge>
{
    private transactionSubject = new BehaviorSubject<Charge[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private countSubject = new BehaviorSubject<number>(0);

    private metaSubject = new Subject<ChargesPaginationMeta>();

    public loading$ = this.loadingSubject.asObservable();
    public count$ = this.countSubject.asObservable();
    public meta$ = this.metaSubject.asObservable();

    private cancelCurrentRequest = new Subject();

    constructor(private chargesService: ChargesService) {
    }

    loadCharges(
            orgId: number,
            unitId: number,
            search: string = '',
            status: string = 'all',
            sortColumn: string = 'activeAfter',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: ChargeDatasourceOptions = {},
    ) {

        this.loadingSubject.next(true);

        this.cancelCurrentRequest.next(true);

        this.chargesService.getUnitCharges(orgId, unitId, search, status, sortColumn, sortDirection, pageIndex, perPage, options)
                .pipe(takeUntil(this.cancelCurrentRequest))
                .pipe(
                        catchError((err) => of({data: [], meta: {total: 0}}).pipe(tap(_ => console.error(err)))),
                )
                .subscribe((charges: ChargesResponse) => {
                    this.transactionSubject.next(charges.data);
                    const total = (charges.meta.total) ? charges.meta.total : 0;
                    this.metaSubject.next(charges.meta);
                    this.countSubject.next(total);
                    this.loadingSubject.next(false);
                });
    }

    getCsv(
            orgId: number,
            unitId: number,
            search: string = '',
            status: string = 'all',
            sortColumn: string = 'activeAfter',
            sortDirection: string = 'desc',
            options: ChargeDatasourceOptions = {},
    ) {
        options.csv = 1;
        return this.chargesService.getUnitCharges(orgId, unitId, search, status, sortColumn, sortDirection, 1, -1, options);
    }

    connect(): Observable<Charge[]> {
        return this.transactionSubject.asObservable();
    }

    disconnect(): void {
        this.transactionSubject.complete();
        this.loadingSubject.complete();
        this.countSubject.complete();
    }

}


export class ChargesResponse
{
    data: Charge[];
    links: PaginationLinks;
    meta: ChargesPaginationMeta;

    constructor(request: any) {
        this.data = (request.data && request.data.length) ? request.data.map(tx => {
            return new Charge(tx);
        }) : [];
        this.links = new PaginationLinks(request.links);
        this.meta = new ChargesPaginationMeta(request.meta);
    }
}


export class ChargesPaginationMeta
{
    currentPage: number;
    from: number;
    lastPage: number;
    path: string;
    perPage: number;
    to: number;
    total: number;
    original: number;
    totalBalance: number;
    totalCharges: number;

    constructor(meta: any) {
        this.currentPage = meta.current_page || 0;
        this.from = meta.from || 0;
        this.lastPage = meta.last_page || 0;
        this.path = meta.path || null;
        this.perPage = meta.per_page || 0;
        this.to = meta.to || 0;
        this.total = meta.total || 0;
        this.original = meta.original || 0;
        this.totalBalance = meta.totalBalance || 0;
        this.totalCharges = meta.totalCharges || 0;
    }
}
