import { NgModule } from '@angular/core';
import { EmptyCellDirective } from './empty-cell.directive';
import { ToolbarSpacerDirective } from './toolbar-spacer.directive';
import { ToolbarTitleDirective } from './toolbar-title.directive';
import { ButtonSpacerDirective } from './button-spacer.directive';

@NgModule({
    imports: [],
    declarations: [
        ButtonSpacerDirective,
        EmptyCellDirective,
        ToolbarSpacerDirective,
        ToolbarTitleDirective,
    ],
    exports: [
        ButtonSpacerDirective,
        EmptyCellDirective,
        ToolbarSpacerDirective,
        ToolbarTitleDirective,
    ],
})
export class SharedDirectivesModule
{
}
