import { Component, OnInit } from '@angular/core';
import { SidenavContentRef } from '../../sidenav/sidenav-content/sidenav-content-ref';

@Component({
    selector: 'app-notifications-sidenav',
    templateUrl: './notifications-sidenav.component.html',
})
export class NotificationsSidenavComponent implements OnInit
{
    isLoaded = false;

    constructor(
            private _sidenavRef: SidenavContentRef<NotificationsSidenavComponent>,
    ) {
    }

    ngOnInit() {
        // TODO: load notifications (simulating load wiht setTimeout for now...
        setTimeout(() => {
            this.isLoaded = true;
            this._sidenavRef.loading = false;
        }, 1000);
    }
}
