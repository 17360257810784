import { Liability } from 'app/models/entities/accounting/liability';
import { FixedAsset } from 'app/models/entities/accounting/fixed-asset';
import { Equity } from 'app/models/entities/accounting/equity';
import { Category } from 'app/models/entities/accounting/category';

export class TransactionRuleAction
{
    id: number;
    transactionType: string;
    accountType: string;
    accountId: number;

    memo: string;

    account: Category | Equity | FixedAsset | Liability;

    constructor(req: any) {
        this.id = req.id;
        this.transactionType = req.transactionType;
        this.accountType = req.accountType;
        this.accountId = req.accountId;
        this.memo = req.memo;

        if (this.accountType.includes('Category')) {
            this.account = new Category(req.account);
        } else if (this.accountType.includes('Equity')) {
            this.account = new Equity(req.account);
        } else if (this.accountType.includes('FixedAsset')) {
            this.account = new FixedAsset(req.account);
        } else if (this.accountType.includes('Liability')) {
            this.account = new Liability(req.account);
        }
    }
}
