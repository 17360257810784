<mat-toolbar sidenavContentHeader>
    <toolbar-title>{{ title }}</toolbar-title>

    <toolbar-spacer></toolbar-spacer>

    <button mat-icon-button disableRipple [attr.aria-label]="'Close ' + title" (click)="closeContent()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<ng-template sidenavContentContainer></ng-template>

<mat-toolbar #sidenavActions [hidden]="!showActions"></mat-toolbar>

<div *ngIf="isLoadingContent" class="sidenav-loading animate fadeIn">
    <mat-spinner [diameter]="60" [strokeWidth]="8"></mat-spinner>
</div>
